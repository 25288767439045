import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthModuleConfig, DataModelsModule } from '@dc/data-models';
import { HttpLoaderFactory, SharedModule } from '@dc/shared';
import { AuthModule } from '@dc/auth';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpBackend, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { DebtsModule } from '@dc/debts';
import { DataDebtorsModule } from '@dc/debtors';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { HeaderComponent } from './components/main-container/header/header.component';
import { appRoutes } from './app.routes';
import { MyOrdersModule } from './modules/my-orders/my-orders.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { catchError, map, Observable, of } from 'rxjs';

const authModuleConfig: AuthModuleConfig = {
  appNameKey: 'COLLECTOR',
  appName: 'collector',
  apiUrl: environment.apiUrl,
  apiPublicUrl: environment.apiPublicUrl,
};

function initializeApp(httpClient: HttpClient): () => Observable<boolean> {
  return () =>
    httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}`, 'callback').pipe(
      map(() => true),
      catchError((err) => {
        console.error(err);
        return of(false);
      })
    );
}

@NgModule({
  declarations: [AppComponent, MainContainerComponent, HeaderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,

    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpBackend] },
      defaultLanguage: 'pl',
    }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot(),

    DataModelsModule,
    SharedModule.forRoot(),
    AuthModule.forRoot(authModuleConfig),

    DebtsModule.forRoot(environment),
    DataDebtorsModule.forRoot(environment),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),

    MyOrdersModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [HttpClient],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
