<div class="debts md:m-8" *ngIf="vm$ | async as vm">

  <ng-template #assignedDebtsDetailsTemplateRef>
    <dc-assigned-debts-details (detailsPanelClose)="closeDetailsPanel()"></dc-assigned-debts-details>
  </ng-template>

  <div>
    <h1 class="mx-4 my-4">{{'ASSIGNED_DEBTS.ASSIGNED_DEBTS' | translate}}</h1>
  </div>

  <div class="dcol-table__container">
    <table
      class="dcol-table"
      aria-describedby="section-header__title"
      mat-table [dataSource]="vm.allDebts || []"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="assignedDate">
        <th mat-header-cell *matHeaderCellDef>{{'ASSIGNED_DEBTS.LIST.ASSIGNED_DATE' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdDate | date:'medium' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ 'ASSIGNED_DEBTS.LIST.STATUS' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ 'DEBTS.STATUS.' + element.status | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>{{'ASSIGNED_DEBTS.LIST.CREATED_DATE' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.debt.createdDate | date:'medium' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="currentBalance">
        <th mat-header-cell *matHeaderCellDef>{{ 'ASSIGNED_DEBTS.LIST.CURRENT_BALANCE' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.debt.invoice.currentBalance | amount }}</td>
      </ng-container>

      <ng-container matColumnDef="invoiceNumber">
        <th mat-header-cell *matHeaderCellDef>{{ 'ASSIGNED_DEBTS.LIST.INVOICE_NUMBER' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.debt.invoice.number }}</td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>{{ 'ASSIGNED_DEBTS.LIST.DEBTOR' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.debt.name}}</td>
      </ng-container>

      <ng-container matColumnDef="invoicePaymentDate">
        <th mat-header-cell *matHeaderCellDef>{{ 'ASSIGNED_DEBTS.LIST.PAYMENT_DATE' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{element.debt.invoice.paymentDate | date}}
          <strong>[{{ element.debt.invoice.paymentDate | pastDueDays }} {{ 'ASSIGNED_DEBTS.LIST.DAYS' | translate }}]</strong> </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>{{ 'ASSIGNED_DEBTS.LIST.ADDRESS' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <dc-address [address]="element.debt.address" [showCountry]="false"></dc-address>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="dcol-table__action-column">
          <button type="button" mat-icon-button>
            <mat-icon fontSet="material-icons-outlined">arrow_forward_ios</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <tr class="dcol-table__row-clickable" mat-row *matRowDef="let element; columns: displayedColumns;" (click)="openSidePanel(assignedDebtsDetailsTemplateRef, element)"></tr>

      <tr class="mat-row mat-no-data-row" *matNoDataRow>
        <td class="mat-cell dcol-table__no-data-row" colspan="9999">{{ 'ASSIGNED_DEBTS.LIST.EMPTY' | translate }}</td>
      </tr>

    </table>
  </div>

  <mat-paginator
    [length]="vm.totalCount"
    [pageSize]="vm.requestParams.limit"
    [pageIndex]="vm.requestParams.page"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event, vm.requestParams)"
  ></mat-paginator>

</div>
