import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CollectorDebtsEntity, CollectorDebtsFacade, CollectorDebtStatusEnum, DebtStatusEnum } from '@dc/debts';

@Component({
  selector: 'dc-assigned-debts-details',
  templateUrl: './assigned-debts-details.component.html',
  styleUrls: ['./assigned-debts-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignedDebtsDetailsComponent {
  @Output() detailsPanelClose: EventEmitter<void> = new EventEmitter();

  public selectedDebt$ = this.collectorDebtsFacade.selectedCollectorDebts$;

  public readonly collectorDebtsStatusEnum = CollectorDebtStatusEnum;

  constructor(private collectorDebtsFacade: CollectorDebtsFacade) {}

  public removeAssignment(debt: CollectorDebtsEntity): void {
    this.detailsPanelClose.emit();
    this.collectorDebtsFacade.removeAssignment(debt.debt.id);
  }

  public acceptAssignment(debt: CollectorDebtsEntity): void {
    this.detailsPanelClose.emit();
    this.collectorDebtsFacade.acceptAssignment(debt.debt.id);
  }
}
