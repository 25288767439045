import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CollectorDebtsEntity, CollectorDebtsFacade, CollectorDebtsRequestParams, DebtsEntity } from '@dc/debts';
import { clone, PanelService, ToastService } from '@dc/shared';
import { PAGE_SIZE_OPTIONS, RequestParams } from '@dc/data-models';
import { PageEvent } from '@angular/material/paginator';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'dc-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyOrdersComponent implements OnInit {
  public displayedColumns: string[] = [];
  public pageSizeOptions = clone(PAGE_SIZE_OPTIONS);
  public requestParams$ = this.collectorDebtsFacade.requestParams$;
  public allDebts$ = this.collectorDebtsFacade.allDebts$;
  public totalCount$ = this.collectorDebtsFacade.totalCount$;
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    tap((isHandset) => {
      this.displayedColumns = isHandset ? this.displayedColumnsMobile : this.displayedColumnsDesktop;
    })
  );

  public vm$ = combineLatest([this.requestParams$, this.allDebts$, this.totalCount$, this.isHandset$]).pipe(
    map(
      ([requestParams, allDebts, totalCount, isHandset]): {
        requestParams: RequestParams;
        allDebts: CollectorDebtsEntity[];
        totalCount: number;
        isHandset: boolean;
      } => ({ requestParams, allDebts, totalCount, isHandset })
    )
  );

  private displayedColumnsDesktop: string[] = [
    'status',
    'assignedDate',
    'fullName',
    'address',
    'createdDate',
    'currentBalance',
    'invoicePaymentDate',
    'invoiceNumber',
    'actions',
  ];

  private displayedColumnsMobile: string[] = ['status', 'fullName', 'address', 'currentBalance', 'actions'];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private collectorDebtsFacade: CollectorDebtsFacade,
    private panelService: PanelService,
    private toastService: ToastService,
    private vcf: ViewContainerRef
  ) {}

  public ngOnInit(): void {
    this.collectorDebtsFacade.init();
  }

  public onPageChange(event: PageEvent, requestParams: CollectorDebtsRequestParams): void {
    this.collectorDebtsFacade.loadDebts({
      ...requestParams,
      page: event.pageIndex,
      limit: event.pageSize,
    });
  }

  public openSidePanel(templateRef: TemplateRef<unknown>, debt?: DebtsEntity): void {
    if (!debt) {
      return;
    }

    this.collectorDebtsFacade.selectDebt(debt.id);
    const portal = new TemplatePortal(templateRef, this.vcf);
    this.panelService.open(portal);
  }

  public closeDetailsPanel(): void {
    this.panelService.close().then();
  }
}
