<mat-toolbar color="primary" class="topbar">
  <div class="topbar__container">
    <button mat-icon-button (click)="toggleSideBar()">
      <mat-icon>menu</mat-icon>
    </button>
    <a class="topbar__logo-link" routerLink="/">
      <img class="topbar__logo" src="assets/logo_odzyskator.svg" alt="Odzyskator logo" />
    </a>
  </div>

  <button
    mat-icon-button
    matTooltip="{{ 'AUTH.LOGOUT' | translate }}"
    (click)="logout()"
    [disabled]="(isAuthenticated$ | async) === false"
  >
    <mat-icon>power_settings_new</mat-icon>
  </button>
</mat-toolbar>
