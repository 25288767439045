<dc-header (sidebarToggled)="sideBarOpen = !sideBarOpen"></dc-header>

<mat-drawer-container [hasBackdrop]="drawerHasBackdrop">

  <mat-drawer [mode]="menuDrawerMode" [opened]="sideBarOpen" #mainMenuDrawer>
    <dc-menu [menuItems]="menuItems">
      <ng-container header>{{'APP.NAME.COLLECTOR' | translate}}</ng-container>
      <ng-container version>{{ version }}</ng-container>
      <ng-container user-info *ngIf="vm$ | async as vm">
        <div class="mx-5 mt-5" *ngIf="!vm.isAuthenticated">
          <dc-login-encouragement></dc-login-encouragement>
        </div>
        <dc-profile-info *ngIf="vm.isAuthenticated" [profile]="vm.user"></dc-profile-info>
      </ng-container>
    </dc-menu>
  </mat-drawer>
  <mat-drawer-content class="panel-content" >
    <router-outlet></router-outlet>
  </mat-drawer-content>
  <mat-drawer position="end" #drawer mode="over" class="panel-drawer" disableClose>
    <ng-template [cdkPortalOutlet]="panelService.panelPortal | async"></ng-template>
    <button class="panel-drawer__close" type="button" mat-icon-button (click)="panelService.close()"><span class="material-icons">close</span></button>
  </mat-drawer>
</mat-drawer-container>

<!-- left for future use, customize behavior based on device -->
<div *ngIf="breakpoint$ | async"></div>
