import { Routes } from '@angular/router';
import { AuthGuard } from '@dc/auth';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { MyOrdersComponent } from './modules/my-orders/my-orders/my-orders.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: MainContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'debts-map',
        pathMatch: 'full',
      },
      {
        path: '*',
        redirectTo: 'debts-map',
        pathMatch: 'full',
      },
      {
        path: 'my-orders',
        canActivate: [AuthGuard],
        component: MyOrdersComponent,
        loadChildren: () => import('./modules/my-orders/my-orders.module').then((m) => m.MyOrdersModule),
      },
      {
        path: 'debts-map',
        loadChildren: () => import('./modules/debts-map/debts-map.module').then((m) => m.DebtsMapModule),
      },
    ],
  },
];
