import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { AuthFacade, AuthService } from '@dc/auth';

@Component({
  selector: 'dc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Output() sidebarToggled: EventEmitter<void> = new EventEmitter();

  public isAuthenticated$ = this.authFacade.isAuthenticated$;
  constructor(private authService: AuthService, private authFacade: AuthFacade) {}

  public toggleSideBar(): void {
    this.sidebarToggled.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  public logout(): void {
    this.authService.logout(null, '/');
  }
}
