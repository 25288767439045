<div class="debts-map-details p-8" *ngIf="selectedDebt$ | async as selectedDebt">

  <div class="grid grid-cols-2 gap-4 pb-2 mb-2 border-b">
    <div>
      <p>{{ 'DEBTS_MAP.CREATED_DATE' | translate }}:</p>
      <p >{{ selectedDebt.debt.invoice.createdDate | date }}</p>
    </div>
    <div>
      <p>{{ 'DEBTS_MAP.INVOICE_PAST_DUE_DAYS' | translate }}:</p>
      <p>{{ selectedDebt.debt.invoice.paymentDate | pastDueDays }} {{'DEBTS_MAP.DAYS' | translate}}</p>
    </div>

    <div>
      <p>{{ 'DEBTS_MAP.COMMISSION' | translate }}:</p>
      <p>-</p>
    </div>
    <div>
      <p>{{ 'DEBTS_MAP.INVOICE_CURRENT_BALANCE' | translate }}:</p>
      <p>{{ selectedDebt.debt.invoice.currentBalance | amount }}</p>
    </div>

  </div>

  <div class="flex mb-2 pb-2 border-b">
    <p class="w-24">
      {{ 'DEBTS_MAP.DEBTOR' | translate }}:
    </p>
    <p>
      <span>{{ selectedDebt.debt.name }}</span><br>
      <dc-address [address]="selectedDebt.debt.address" [showCountry]="false"></dc-address>
      <a *ngIf="selectedDebt.debt.email" href="mailto:{{selectedDebt.debt.email}}" target="_blank" class="debts-map__info-link has-mat-icon">
        <mat-icon inline fontSet="material-icons-outlined">email</mat-icon>
        {{selectedDebt.debt.email}}
      </a>
      <br>
      <a *ngIf="selectedDebt.debt.phone" href="tel:{{selectedDebt.debt.phone}}" target="_blank" class="debts-map__info-link has-mat-icon">
        <mat-icon inline fontSet="material-icons-outlined">phone</mat-icon>
        {{selectedDebt.debt.phone}}
      </a>
    </p>
  </div>

  <div class="mb-4">
    <dc-debt-queue
      class="mt-4 mb-3 block"
      [collectorQueueNumber]="selectedDebt.debt.collectorQueNumber"
      [queueLength]="selectedDebt.debt.queNumber"
      [collectorStatus]="selectedDebt.debt.collectorStatus">
    </dc-debt-queue>
  </div>

  <div class="mb-4">
    <button type="button" mat-flat-button color="accent" (click)="removeAssignment(selectedDebt)"
            *ngIf="selectedDebt.status === collectorDebtsStatusEnum.ACTIVE || selectedDebt.status === collectorDebtsStatusEnum.QUEUED">
      {{ 'ASSIGNED_DEBTS.REMOVE_ASSIGNMENT' | translate }}
    </button>
    <button type="button" mat-flat-button color="accent" (click)="acceptAssignment(selectedDebt)" *ngIf="selectedDebt.status === collectorDebtsStatusEnum.WAITING_FOR_ACCEPT">
      {{ 'ASSIGNED_DEBTS.ACCEPT_ASSIGNMENT' | translate }}
    </button>
  </div>

  <div class="mb-4">
    <p class="mb-2">
      {{ 'DEBTS_MAP.DOCUMENTS' | translate }}:
    </p>
    <table>
      <tr class="text-left">
        <th>{{ 'DEBTS_MAP.INVOICE_NUMBER' | translate }}</th>
        <th>{{ 'DEBTS_MAP.INVOICE_INITIAL_VALUE' | translate }}</th>
        <th>{{ 'DEBTS_MAP.INVOICE_PAYMENT_DATE' | translate }}</th>
      </tr>
      <tr>
        <td>{{selectedDebt.debt.invoice.number}}</td>
        <td>{{selectedDebt.debt.invoice.initialValue | amount}}</td>
        <td>{{selectedDebt.debt.invoice.createdDate | date}}</td>
      </tr>
    </table>

  </div>

</div>
