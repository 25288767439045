import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { APP_META, MenuItem, PanelService } from '@dc/shared';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { combineLatest, distinctUntilChanged, filter, map, Observable, tap } from 'rxjs';
import { AuthFacade, Profile } from '@dc/auth';

@Component({
  selector: 'dc-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainContainerComponent implements OnInit {
  @ViewChild('drawer', { static: true }) private drawer!: MatDrawer;
  @ViewChild('mainMenuDrawer', { static: true }) private mainMenuDrawer!: MatDrawer;

  public sideBarOpen = false;
  public version = APP_META.version;
  public menuDrawerMode: MatDrawerMode = 'over';
  public drawerHasBackdrop = true;

  public vm$ = combineLatest([this.authFacade.isAuthenticated$, this.authFacade.user$]).pipe(
    map(([isAuthenticated, user]): { isAuthenticated: boolean; user: Profile | null } => {
      const myOrders = this.menuItems.find((i) => i.labelKey === 'MENU.MY_ORDERS');
      myOrders ? (myOrders.disabled = !isAuthenticated) : null;
      this.menuItems = [...this.menuItems];
      return { isAuthenticated, user };
    })
  );

  public menuItems: MenuItem[] = [
    {
      labelKey: 'MENU.MAP',
      routerLink: '/debts-map',
      icon: 'map',
    },
    {
      labelKey: 'MENU.MY_ORDERS',
      routerLink: '/my-orders',
      icon: 'local_atm',
    },
  ];

  // left for future use, the Breakpoints can be used to set different behaviors on different devices
  public readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Medium, Breakpoints.HandsetPortrait, Breakpoints.TabletLandscape, '(min-width: 1280px)'])
    .pipe(
      // tap((value) => console.log(value)),
      distinctUntilChanged()
    );

  private isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  constructor(
    public panelService: PanelService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private authFacade: AuthFacade
  ) {}

  public ngOnInit(): void {
    this.panelService.panel = this.drawer;
    if (this.breakpointObserver.isMatched('(min-width: 1280px)')) {
      this.sideBarOpen = true;
      this.menuDrawerMode = 'side';
      this.drawerHasBackdrop = false;
    }
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((_) => {
      if (this.menuDrawerMode === 'over') {
        this.sideBarOpen = false;
      }
      this.panelService.close().then();
    });
  }

  public onSidebarToggled(): void {
    this.panelService.close().then();
  }
}
