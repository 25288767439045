import { NgModule } from '@angular/core';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { SharedModule } from '@dc/shared';
import { AssignedDebtsDetailsComponent } from './my-orders/assigned-debts/assigned-debts-details.component';
import { DebtsModule } from '@dc/debts';

@NgModule({
  declarations: [MyOrdersComponent, AssignedDebtsDetailsComponent],
  imports: [SharedModule, DebtsModule],
})
export class MyOrdersModule {}
