import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { APP_META } from '@dc/shared';
import { AuthFacade } from '@dc/auth';

@Component({
  selector: 'dc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(private titleService: Title, private translateService: TranslateService, private authFacade: AuthFacade) {
    this.authFacade.autoLogin();
    this.printVersionInfo();
  }

  // TODO: check why TranslateService does not work in here
  // public ngOnInit(): void {
  //   this.titleService.setTitle(this.translateService.instant('APP.NAME.TITLE'));
  // }

  private printVersionInfo(): void {
    const styles = 'background: #00ff4b; color: #53575a; padding: 5px 10px;';
    console.log(`%c${APP_META.name} Admin`, styles + ' font-size: 18px');
    console.log(
      `%cVersion: ${APP_META.version}\nCommit hash: ${APP_META.commitHash}\nBuild date: ${APP_META.buildDate}\nPowered by Maio Software House\nmaiosoftwarehouse.com`,
      styles
    );
  }
}
